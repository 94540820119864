import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { triggerGTAGInternalNavEvent } from '@/utils/analyticTracks';

import paths from '../../../../AppRouter/paths';
import { PlayersContext } from '../../../../context/PlayersContext';
import { IPickem } from '../../../../context/type';
import { RoutesEnum } from '../../../../enums/routes.enum';

import {
  ContainerNavLink,
  DropdownListContainer,
  DropdownListItemRight,
  LinkBlock,
  StyledDropdownListButton,
  Wrapper,
} from './LeaderBoard.styles';
import './style.css';
import { useOverall } from './useOverall';

type Props = {
  periodDate: string;
  currentWeeklyIndex: number;
  currentMonthlyIndex: number;
  setCurrentWeeklyIndex: React.Dispatch<React.SetStateAction<number>>;
  setCurrentMonthlyIndex: React.Dispatch<React.SetStateAction<number>>;
};

const LeaderBoardNavigation: React.FC<Props> = ({
  periodDate,
  currentWeeklyIndex,
  currentMonthlyIndex,
  setCurrentWeeklyIndex,
  setCurrentMonthlyIndex,
}) => {
  const isDesktop = window.innerWidth > 768;
  const lng = localStorage.getItem('i18nextLng');
  const navigate = useNavigate();
  // const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const [isDropdown, setIsDropdown] = useState(false);
  const { pickemsList, isLoaded, setTournamentData, tournamentData } =
    useContext(PlayersContext);
  const {
    currentOverallSelection,
    setCurrentOverallSelection,
    overallPeriodsState,
    period,
  } = useOverall({
    setIsDropdown,
  });
  const [filteredAllPlayersData, setFilteredAllPlayersData] = useState(
    pickemsList.filter(
      (pickem: any) =>
        pickem.status === 'COMPLETE' || pickem.status === 'IN_PLAY',
    ),
  );

  useEffect(() => {
    setFilteredAllPlayersData(
      pickemsList.filter(
        (pickem: any) =>
          pickem.status === 'COMPLETE' || pickem.status === 'IN_PLAY',
      ),
    );
  }, [pickemsList]);

  const handleOverallSelection = (periodIndex: number) => () => {
    setCurrentOverallSelection(overallPeriodsState[periodIndex]);
    setTournamentData(overallPeriodsState[periodIndex]);
    setIsDropdown(false);
  };

  const handleWeeklySelection =
    (pickemIndex: number, pickem: IPickem) => () => {
      setCurrentWeeklyIndex(pickemIndex);
      setIsDropdown(false);

      navigate(`${RoutesEnum.weekly}/${pickem.pickem_id}`);
    };

  const handleMonthlySelection = (tournamentIndex: number) => () => {
    setCurrentMonthlyIndex(tournamentIndex);
    setIsDropdown(false);
  };

  const toggleDropdown = () => setIsDropdown((value) => !value);
  const { pathname } = useLocation();
  const active = [paths.myLeagues, paths.admin, paths.userLeague];
  const displayPaths = [RoutesEnum.weekly, paths.monthly];

  return (
    <>
      <Wrapper
        style={{
          margin:
            isDesktop &&
            (displayPaths.includes(pathname) ||
              pathname.includes(paths.leaderboardScores))
              ? '48px auto 0 auto'
              : '',
          borderRadius: isDesktop ? '24px 24px 0 0' : '0',
        }}
      >
        <LinkBlock
          style={{
            borderRadius: isDesktop ? '24px 24px 0 0' : '0',
          }}
        >
          <NavLink
            className={() => {
              const activeLink = active.includes(pathname);
              return activeLink ? 'active' : 'inactive';
            }}
            onClick={() =>
              triggerGTAGInternalNavEvent('https://atpgamezone.com/my-leagues')
            }
            to={paths.myLeagues}
          >
            {isDesktop ? (
              <ContainerNavLink isActive={active.includes(pathname)}>
                {/* <div style={{ width: '60px' }}>
                  <StyledDropdownImage src={pathname === paths.myLeagues ? mly : ml} className="active" />
                </div> */}
                My Leagues
              </ContainerNavLink>
            ) : (
              <>My Leagues</>
            )}
          </NavLink>

          <NavLink
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            onClick={() =>
              triggerGTAGInternalNavEvent('https://atpgamezone.com/monthly')
            }
            to={paths.monthly}
          >
            {isDesktop ? (
              <ContainerNavLink isActive={pathname === paths.monthly}>
                {/* <div style={{ width: '60px' }}>
                  <StyledDropdownImage src={pathname === paths.monthly ? overActive : over} className="active" />
                </div> */}
                Monthly
              </ContainerNavLink>
            ) : (
              <>Monthly</>
            )}
          </NavLink>

          <NavLink
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            onClick={() =>
              triggerGTAGInternalNavEvent('https://atpgamezone.com/weekly')
            }
            to={paths.weekly}
          >
            <ContainerNavLink
              isActive={
                pathname === paths.weekly ||
                pathname.includes(paths.leaderboardScores) ||
                pathname.includes(RoutesEnum.weekly)
              }
            >
              Gameweek
            </ContainerNavLink>
          </NavLink>
        </LinkBlock>
      </Wrapper>

      {(displayPaths.includes(pathname) ||
        pathname.includes(RoutesEnum.weekly) ||
        pathname.includes(paths.leaderboardScores)) && (
        <Wrapper
          style={{
            margin: '0 auto',
            background: '#051224',
          }}
        >
          <DropdownListContainer
            style={{
              position: 'relative',
              flexDirection: 'row',
              justifyContent:
                !isDesktop && pathname.includes(RoutesEnum.weekly)
                  ? 'normal'
                  : 'space-evenly',
              transform: 'none',
              padding: '0 16px',
              width: '100%',
              scrollbarWidth: 'thin',
              scrollbarColor: 'transparent transparent',
              overflowX: 'auto',
            }}
          >
            {isLoaded &&
            (pathname.includes(paths.leaderboardScores) ||
              pathname.includes(RoutesEnum.weekly))
              ? filteredAllPlayersData.map((pickem: any, index: number) => (
                  <DropdownListItemRight key={index}>
                    <StyledDropdownListButton
                      onClick={handleWeeklySelection(index, pickem)}
                      style={
                        currentWeeklyIndex === index
                          ? {
                              color: 'black',
                              background: 'white',
                              fontFamily: 'RocGroteskBold, sans-serif',
                              fontWeight: 700,
                            }
                          : {}
                      }
                    >
                      {lng === 'en'
                        ? pickem.title_en
                        : pickem.title_fr || pickem.title_en}
                    </StyledDropdownListButton>
                  </DropdownListItemRight>
                ))
              : tournamentData?.length && pathname === paths.monthly
                ? tournamentData.map((tournament: any, index: number) => (
                    <DropdownListItemRight key={index}>
                      <StyledDropdownListButton
                        onClick={handleMonthlySelection(index)}
                        style={
                          currentMonthlyIndex === index
                            ? {
                                color: 'black',
                                background: 'white',
                                fontFamily: 'RocGroteskBold, sans-serif',
                                fontWeight: 700,
                              }
                            : {}
                        }
                      >
                        {tournament.title}
                      </StyledDropdownListButton>
                    </DropdownListItemRight>
                  ))
                : null}
          </DropdownListContainer>
        </Wrapper>
      )}

      <Outlet />
    </>
  );
};

export default LeaderBoardNavigation;
