import React, { createContext, useEffect, useState } from 'react';

import paths from '@/AppRouter/paths';
import {
  FetchDevision,
  FetchPickemList,
  FetchSinglePickem,
} from '@/api/devisionApi';
import { FetchOverall } from '@/api/overallApi';
import { fetchTournamentApi } from '@/api/tournamentApi';

import { localStorageGet } from '@/utils/localStorage';

import { IPickem, IPlayer } from './type';

const PlayersContext = createContext<any>({});

// const COLLECTIVE_LEADERBOARD = process.env.REACT_APP_COLLECTIVE_LEADERBOARD;
export function sortAndReduceArray(data: any, index: number) {
  return data
    ?.sort((a: any, b: any) => {
      return new Date(a['start']).getTime() > new Date(b['start']).getTime()
        ? -1
        : 1;
    })
    ?.filter((_: any, elIdx: any) => {
      return elIdx < index;
    });
}

const NO_SELECTIONS_DATA_PATHS = [
  paths.home,
  paths.scores,
  paths.weekly,
  paths.rankings,
];

const ReferenceDataContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [allPlayersData, setAllPlayersData] = useState<IPickem[]>([]);
  const [pickemsList, setPickemsList] = useState<IPickem[]>([]);
  const [leaderboardUser, setLeaderboardUser] = useState<IPlayer>();
  const [leaderboardData, setLeaderboardData] = useState<IPlayer[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [tournamentData, setTournamentData] = useState<any>();
  const [isOverallLoaded, setIsOverallLoaded] = useState<boolean>(false);
  const [isDoublePickem, setIsDoublePickem] = useState(false);

  const handleFetchSinglePickem = async (pickemId: number) => {
    if (isLoaded) {
      setIsLoaded(false);
    }
    const singlePickem = await FetchSinglePickem(pickemId);
    if (allPlayersData && singlePickem.status) {
      setAllPlayersData((prev: Array<any>) => {
        prev.splice(0, 1);
        prev.unshift(singlePickem);
        return prev;
      });
    }
    setIsLoaded(true);
  };
  // const jwt = localStorageGet('jwt');
  const fetchAllPlayersData = async () => {
    if (isLoaded) {
      setIsLoaded(false);
    }

    const data2 = await FetchDevision();
    const resultData = sortAndReduceArray(data2, data2?.length);
    if (
      resultData[0].status === 'UPCOMING' &&
      resultData[1].status !== 'DRAFT' &&
      resultData?.at(1)?.selections?.length !== 0
    ) {
      setIsDoublePickem(true);
    } else {
      setIsDoublePickem(false);
    }
    setAllPlayersData(resultData);
    localStorage.setItem('data', JSON.stringify(resultData));

    setIsLoaded(true);
  };

  const fetchPickemsList = async () => {
    if (isLoaded) {
      setIsLoaded(false);
    }

    const data2 = await FetchPickemList();

    const resultData = sortAndReduceArray(data2, data2.length);
    setPickemsList(resultData);
    if (
      resultData[0].status === 'UPCOMING' &&
      resultData[1].status !== 'DRAFT' &&
      resultData?.at(1)?.selections?.length !== 0
    ) {
      setIsDoublePickem(true);
    } else {
      setIsDoublePickem(false);
    }

    setIsLoaded(true);
  };

  const fetchOverallData = async (tournamentId: number) => {
    if (isOverallLoaded) {
      setIsOverallLoaded(false);
    }
    const data = await FetchOverall(
      process.env.REACT_APP_COLLECTIVE_LEADERBOARD,
      tournamentId,
    );

    const sortedData = await data[0].leaderboard.sort(
      (a: any, b: any) => a.points - b.points,
    );
    let filteredData;

    const currentPlayerInLeaderboardIndex = sortedData.find(
      (elem: any, index: number) => elem.user_id === data[1].position.user_id,
    );

    if (currentPlayerInLeaderboardIndex) {
      filteredData = data[0].leaderboard.filter(
        (elem: any) => elem.user_id !== currentPlayerInLeaderboardIndex.user_id,
      );
      setLeaderboardData([data[1].position, ...filteredData]);
    } else if (!currentPlayerInLeaderboardIndex) {
      filteredData = [data[1].position, ...data[0].leaderboard];
    }
    //   data[1].position?.user_id < 9
    //     ? setLeaderboardData(filteredData)
    //     : setLeaderboardData([data[1].position, ...filteredData]);
    setLeaderboardData(filteredData);
    setLeaderboardUser(data[1].position);
    setIsOverallLoaded(true);
  };
  const fetchTournamentData = async () => {
    const data = await fetchTournamentApi();
    setTournamentData(data);
    return data;
  };

  useEffect(() => {
    if (!tournamentData) {
      fetchTournamentData();
    }

    if (
      !NO_SELECTIONS_DATA_PATHS.includes(window.location.pathname) &&
      localStorageGet('jwt')
    ) {
      fetchAllPlayersData();
    } else if (
      [paths.weekly, paths.rankings].includes(window.location.pathname)
    ) {
      fetchAllPlayersData();
      fetchPickemsList();
    }
  }, []);

  return (
    <PlayersContext.Provider
      value={{
        fetchAllPlayersData,
        handleFetchSinglePickem,
        fetchOverallData,
        sortAndReduceArray,
        fetchTournamentData,
        fetchPickemsList,
        setTournamentData,
        allPlayersData,
        pickemsList,
        leaderboardData,
        leaderboardUser,
        isOverallLoaded,
        isLoaded,
        tournamentData,
        isDoublePickem,
      }}
    >
      {children}
    </PlayersContext.Provider>
  );
};

export { PlayersContext, ReferenceDataContextProvider };
